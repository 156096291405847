import { CrudAction } from './groups.enums';

export const CrudActions = [CrudAction.VIEW, CrudAction.CHANGE, CrudAction.ADD, CrudAction.DELETE];

const BlockedCrudMethods = [
  'ffsecurity.add_notification',
  'ffsecurity.delete_notification',
  'ffsecurity.change_all_own_sessions',
  'ffsecurity.add_all_own_sessions'
];

export const BuiltInCrudModels = [
  'area',
  'faceevent',
  'faceobject',
  'carevent',
  'carobject',
  'bodyevent',
  'bodyobject',
  'deviceblacklistrecord',
  'dossierlist',
  'dossier',
  'cameragroup',
  'camera',
  'eventepisode',
  'person',
  'uploadlist',
  'upload',
  'user',
  'webhook',
  'videoarchive',
  'counter',
  'metadictionary',
  'notification',
  'report',
  'all_own_sessions'
].map((name) => ({
  name,
  permissions: CrudActions.map((action) => computeAbsolutePermissionString('', name, action)).map((permission) => BlockedCrudMethods.includes(permission) ? null : permission)
}));

export const BuiltInOtherModels = ['configure_ntls', 'batchupload_dossier', 'view_runtimesetting', 'change_runtimesetting', 'view_auditlog'].map((name) =>
  createNewOtherModel('', name)
);

export function computeModelsFromPermissions([plugin, permissions]) {
  const cruds = [];
  const others = [];
  const crudsMap = new Map();

  for (const permission of permissions) {
    (isCrudPermission(permission) ? appendCrudPermissionToModel : appendOtherPermissionToModel)(permission);
  }

  function appendCrudPermissionToModel(permission) {
    const { name, action } = computeCrudModelDescriptor(permission);
    permission = computeAbsolutePermissionString(plugin, permission);
    getCrudModelByName(name).permissions[action] = permission;
  }

  function getCrudModelByName(name) {
    return crudsMap.get(name) || createNewCrudModel(name);
  }

  function createNewCrudModel(name) {
    const model = { name, permissions: ['', '', '', ''] };
    crudsMap.set(name, model);
    cruds.push(model);
    return model;
  }

  function appendOtherPermissionToModel(permission) {
    others.push(createNewOtherModel(plugin, permission));
  }

  return { cruds, others };
}

const CrudPermissionRegExp = /^(view|change|add|delete)_(\w+)$/;

const CrudActionsMap = {
  [CrudAction.VIEW]: 0,
  [CrudAction.CHANGE]: 1,
  [CrudAction.ADD]: 2,
  [CrudAction.DELETE]: 3
};

function isCrudPermission(permission) {
  return CrudPermissionRegExp.test(permission);
}

function computeCrudModelDescriptor(permission) {
  const [, action, name] = CrudPermissionRegExp.exec(permission);
  return { name, action: CrudActionsMap[action] };
}

function createNewOtherModel(plugin, name) {
  const permissions = [computeAbsolutePermissionString(plugin, name)];
  return { name, permissions };
}

function computeAbsolutePermissionString(plugin, model, action = '') {
  if (isOldStylePlugin(plugin)) {
    plugin = '';
  }
  return `ffsecurity${plugin && '_' + plugin}.${action && action + '_'}${model}`;
}

function isOldStylePlugin(plugin) {
  return plugin === 'genetec';
}
