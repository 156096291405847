<template>
  <div class="cols" style="padding: 0">
    <div class="flex-cell--static" style="width: 16rem; background-color: #2f3035">
      <div class="mar-left--2 content-header">
        <h2>
          <span>{{ $tfo('roles') }}</span>
          <el-button circle icon="el-icon-plus" size="mini" style="float: right; margin-right: 1rem" @click="createHandler"></el-button>
        </h2>
      </div>

      <common-list :items="$store.state.groups.items" v-model="group"></common-list>
    </div>
    <div class="group-tabs cell" v-if="group">
      <div class="group-tabs-header">
        <div class="flex-columns flex--cross-center" style="height: 4rem">
          <common-tabs v-model="tab" :items="tabItems" :converter="$tfo"></common-tabs>
        </div>
      </div>

      <div v-if="tab === GroupTabs.Info" class="group-info">
        <el-form
          :size="$vars.sizes.form"
          :label-position="$vars.forms.label_position"
          :labelWidth="$vars.forms.label_width"
          ref="form"
          :name="$options.name"
          :model="group"
          :rules="rules"
        >
          <el-form-item :label="$tf('common.id')" v-if="group.id">
            <span name="id">{{ group.id }}</span>
          </el-form-item>
          <el-form-item :label="$tf('common.name')" prop="name">
            <div @keyup.enter="saveGroupHandler">
              <el-input name="name" v-model="group.name"></el-input>
            </div>
            <el-input name="preventSubmitForm" style="visibility: hidden"></el-input>
          </el-form-item>
          <el-form-item class="mar-top--large">
            <el-button
              name="save-btn"
              type="primary"
              @click="saveGroupHandler"
              size="large"
              :disabled="create ? $hasNoPermission('auth.add_group') : $hasNoPermission('auth.change_group')"
              >{{ $tf('common.save') }}
            </el-button>
          </el-form-item>
        </el-form>
        <confirm-button
          target="role"
          size="mini"
          plain
          @confirm="deleteGroupHandler"
          :disabled="$hasNoPermission('auth.delete_group')"
          class="mar-top--large"
          v-if="!create"
        >
        </confirm-button>
      </div>
      <groups-permissions :permissions="group.permissions" v-if="tab === GroupTabs.Permissions" :plugins="enabledPlugins" @input="changePermission" />
      <div v-else-if="tab === GroupTabs.CameraGroups">
        <!-- Camera groups -->
        <el-table
          name="camera-groups-table"
          class="camera-groups-table table-medium"
          :size="$vars.sizes.table || $vars.sizes.common"
          stripe
          :data="camera_groups"
        >
          <el-table-column prop="name" :label="$tf('name')">
            <template slot-scope="{ row }">
              <i class="icon-cameras"></i><span>{{ row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="" :align="'center'" :label="$tfo('none')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="''" :value="!row.permissions[group.id] && ''" @input="changeCameraGroupHandler(row, '')" :disabled="row.id < 0"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('view')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'view'" :value="row.permissions[group.id]" @input="changeCameraGroupHandler(row, 'view')" :disabled="row.id < 0"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('change')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'edit'" :value="row.permissions[group.id]" @input="changeCameraGroupHandler(row, 'edit')" :disabled="row.id < 0"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="tab === GroupTabs.DossierLists">
        <!-- Watch lists -->
        <el-table name="watch-lists-table" class="watch-lists-table table-medium" :size="$vars.sizes.table || $vars.sizes.common" stripe :data="dossier_lists">
          <el-table-column prop="name" :label="$tf('name')">
            <template slot-scope="{ row }">
              <i class="icon-dot" :style="{ backgroundColor: '#' + row.color }"></i><span>{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('none')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="''" :value="!row.permissions[group.id] && ''" @input="changeWatchListHandler(row, '')" :disabled="row.id < 0"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('view')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'view'" :value="row.permissions[group.id]" @input="changeWatchListHandler(row, 'view')" :disabled="row.id < 0"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('change')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'edit'" :value="row.permissions[group.id]" @input="changeWatchListHandler(row, 'edit')" :disabled="row.id < 0"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTabs from '../common/tabs.vue';
import CommonList from '../common/list.vue';
import GroupsForm from './form.vue';
import GroupsPermissions from './groups.permissions';
import ConfirmButton from '../confirm/confirm-button';

const GroupTabs = {
  Info: 'info',
  Permissions: 'permissions',
  CameraGroups: 'camera_groups',
  DossierLists: 'dossier_lists'
};

let baseRules = {
  name: [{ required: true, message: 'error.required.field', trigger: 'blur' }]
};

export default {
  name: 'page-groups',
  components: {
    CommonTabs,
    CommonList,
    GroupsForm,
    GroupsPermissions,
    ConfirmButton
  },
  data: function () {
    return {
      create: false,
      tab: GroupTabs.Info,
      group: null,
      rules: this.$applyRuleMessages(baseRules),
      GroupTabs,
      plugins: {}
    };
  },
  computed: {
    state() {
      return this.$store.state.groups;
    },
    tabItems() {
      const items = [{ name: GroupTabs.Info }, { name: GroupTabs.DossierLists }, { name: GroupTabs.CameraGroups }, { name: GroupTabs.Permissions }];
      return this.group.id && this.$store.getters.hasAcl ? items : items.slice(0, 1);
    },
    enabledPlugins() {
      return Object.entries(this.plugins).reduce((result, [plugin, permissions]) => {
        if (this.$store.state.config.plugins[plugin] === true) {
          result[plugin] = permissions;
        }
        return result;
      }, {});
    },
    permissions() {
      return this.$store.state.permissions.items || [];
    },
    camera_groups() {
      return this.$store.state.camera_groups.items || [];
    },
    dossier_lists() {
      return this.$store.state.dossier_lists.items || [];
    }
  },
  watch: {
    group(v, p) {
      this.create = v && !v.id;
    }
  },
  mounted() {
    Promise.all([this.$store.dispatch(this.$store.state.users.Action.Get), this.$store.dispatch(this.state.Action.Get)]).then((v) => {
      this.group = this.state.items[0];
    });
    this.fetchPluginPermissions();
  },
  methods: {
    getItemLink(item) {
      return '/groups/' + encodeURIComponent(item.id) + '/';
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    },
    changePermission(permissions) {
      const updatedInstance = { id: this.group.id, permissions };
      this.$store
        .dispatch(this.state.Action.Update, updatedInstance)
        .then((r) => {
          this.$set(this.group, 'permissions', permissions);
          this.$notify({ type: 'success', message: this.$tfo('action | success') });
        })
        .catch(this.handleActionDispatchingError);
    },
    changeCameraGroupHandler(item, v) {
      let permissions = Object.assign({}, item.permissions, { [this.group.id]: v }),
        updatedInstance = { id: item.id, permissions };

      if (!v) permissions[this.group.id] = null;

      this.$store
        .dispatch(this.$store.state.camera_groups.Action.Update, updatedInstance)
        .then((r) => {
          item.permissions = permissions;
          this.$notify({ type: 'success', message: this.$tfo('action | success') });
        })
        .catch(this.handleActionDispatchingError);
    },
    changeWatchListHandler(item, v) {
      let permissions = Object.assign({}, item.permissions, { [this.group.id]: v }),
        updatedInstance = { id: item.id, permissions };

      if (!v) permissions[this.group.id] = null;

      this.$store
        .dispatch(this.$store.state.dossier_lists.Action.Update, updatedInstance)
        .then((r) => {
          item.permissions = permissions;
          this.$notify({ type: 'success', message: this.$tfo('action | success') });
        })
        .catch(this.handleActionDispatchingError);
    },
    saveGroupHandler() {
      this.$refs.form.validate(this.validateHandler);
    },
    validateHandler(valid) {
      if (!valid) return;
      const action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.$store
        .dispatch(action, this.group)
        .then(({ id }) => {
          this.$notify({
            type: 'success',
            message: this.$tf(['common.action', 'common.success'])
          });
          return this.$store.dispatch(this.state.Action.Get).then(() => {
            this.group = this.state.items.find((item) => item.id === id);
            if (action === this.state.Action.Create) {
              return Promise.all([
                this.$store.dispatch(this.$store.state.camera_groups.Action.Get),
                this.$store.dispatch(this.$store.state.dossier_lists.Action.Get)
              ]);
            }
          });
        })
        .catch(this.handleActionDispatchingError);
    },
    deleteGroupHandler() {
      this.$store
        .dispatch(this.state.Action.Delete, this.group)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$store.dispatch(this.state.Action.Get).then((r) => {
            this.group = this.state.items[0];
          });
        })
        .catch(this.handleActionDispatchingError);
    },
    createHandler() {
      this.tab = GroupTabs.Info;
      this.group = Object.assign({}, this.state.item.empty);
    },
    async fetchPluginPermissions() {
      try {
        this.plugins = await this.dispatchFetchPluginPermissionsAction();
      } catch (e) {
        this.handleActionDispatchingError(e);
      }
    },
    dispatchFetchPluginPermissionsAction() {
      const { FetchPluginPermissions } = this.$store.state.permissions.Action;
      return this.$store.dispatch(FetchPluginPermissions);
    },
    handleActionDispatchingError(e) {
      const message = this.$createElement('message-box', { props: { e } });
      this.$notify({ message });
    }
  }
};
</script>
<style lang="stylus">
.group-tabs {
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #36393f;
  overflow: auto;
  max-height: 100%;

  .select-buttons {
    margin-bottom: 2rem;
  }
}

.group-tabs-header {
  height: 6.75rem;
}

.groups-table {
  td.el-table__expanded-cell {
    padding: 0 !important;
  }

  .el-table__empty-block {
    min-height: 24px;
  }
}

.camera-groups-table, .watch-lists-table {
  .el-radio__label {
    display: none;
  }
}
</style>
